@font-face {
  font-family: 'Involve';
  src: url('../fonts/Involve-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Involve';
  src: url('../fonts/Involve-Oblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Involve';
  src: url('../fonts/Involve-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Involve';
  src: url('../fonts/Involve-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Involve';
  src: url('../fonts/Involve-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Involve Bold Oblique';
  src: url('../fonts/Involve-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Involve SemiBold Oblique';
  src: url('../fonts/Involve-SemiBoldOblique.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Involve Medium Oblique';
  src: url('../fonts/Involve-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
