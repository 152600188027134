@media screen and (max-width: 1200px) {
}

@media screen and (min-width: 991px) {
  .desktop-hide {
    display: none !important;
  }
}

@media screen and (min-width:1200px) and (max-width:1440px) {
  .home-page.services {
    .item {

      aspect-ratio: unset;
      min-height: 500px;
      background-size: cover;


      .item-title {
        font-size: clamp(1.5625rem, -1.5625rem + 4.1667vw, 2.1875rem);
      }

      // p.body_3 {
      //   font-size: 14px;
      // }
    }
  }
}

@media screen and (max-width: 991px) {
  .mobile-hide {
    display: none !important;
  }

  .h0,
  .h0_mobile {
    @include h0_mobile();
  }

  h1,
  .h1,
  .h1_mobile {
    @include h1_mobile;
  }

  h2,
  .h2,
  .h2_mobile {
    @include h2_mobile;
  }

  h3,
  .h3,
  .h3_mobile {
    @include h3_mobile;
  }

  h4,
  .h4,
  .h4_mobile {
    @include h4_mobile;
  }

  .body_1,
  .body_1_mobile {
    @include body_1_mobile();
  }

  .body_2,
  .body_2_mobile {
    @include body_2_mobile();
  }

  .body_3,
  .body_3_mobile {
    @include body_3_mobile();
  }

  .body_4,
  .body_4_mobile {
    @include body_4_mobile();
  }

  main {
    border-radius: 0;
  }

  body {
    @include body_1_mobile;

    &.menu-open {
      header.header-page .logo {
        #logo-black {
          opacity: 0;
        }

        #logo-white {
          opacity: 1;
        }
      }
    }
  }

  header.header-page {
    top: 20px;
    // margin-left: 8px;
    // margin-right: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .translate-block-title {
      @include body_1;
    }

    .right-side {
      column-gap: 17px;
    }
  }

  footer.footer-page {
    padding-top: 26px;
    margin-top: 0;

    .col-logo {
      display: flex;
      justify-content: space-between;
      text-align: right;
      margin-bottom: 32px;
    }

    .button-arrow {
      margin-top: 16px;
    }

    .ul-nav {
      margin-top: 42px;
      column-gap: 27px;
      row-gap: 13px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include body_3_mobile;
    }
  }

  // ================================= PAGES =================================

  .home-page {
    &.banner {
      margin-top: 8px;

      .home-page-container {
        // padding-top: 88px;
        // padding-left: 8px;
        // padding-right: 8px;

        padding: 88px 0 8px 0;

        & > .row {
          min-height: calc(100vh - 88px - 8px - 8px - 8px);
          min-height: unset !important;
        }

        .col-top-side {
          .button-circle-container {
            text-align: right;
          }

          h1 {
            margin-bottom: 24px;
            padding-left: 8px;
          }
        }

        .col-lg-4 {
          p {
            padding-left: 8px;
          }
        }

        .col-bottom-side {
          margin-top: 24px;

          .bottom-div-wrap {
            width: calc(100% - 16px);
            padding: 8px;
            column-gap: 8px;
            @include body_3_mobile;
            margin-left: 8px;
            margin-right: 8px;

            .stars-wrap {
              column-gap: 2px;
            }
          }

          .col-lg-4 {
            &:last-child {
              order: -1;

              p {
                margin-bottom: 144px;
                max-width: 85%;
              }
            }
          }
        }

        #bg-video {
          bottom: -30px;
          top: unset;
          left: 0;
          right: 0;
          transform: none;
          max-height: 282px;
          max-width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &.marquee-container {
      margin-top: 52px;
      margin-bottom: 52px;
    }

    &.services {
      margin-top: 52px;
      margin-bottom: 76px;

      .item {
        width: 85vw;
        max-width: 451px;
        // margin-bottom: 0;

        &-col {
          .row {
            flex-wrap: nowrap;
            overflow-x: scroll;
          }
        }
      }
    }

    &.proces {
      margin-top: 52px;
      margin-bottom: 52px;
      overflow-x: hidden;

      .col-undertitle {
        margin-bottom: 56px;
      }

      .item {
        width: 268px;

        &-col {
          .row {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-bottom: 20px;
          }
        }
      }
    }

    &.why-us {
      margin-top: 52px;
      margin-bottom: 52px;
      padding-top: 33px;
      padding-bottom: 26px;

      .col-title {
        margin-bottom: 56px;
      }

      .left-side {
        text-align: center;
        position: unset;
        top: 0;
        padding-bottom: 0;
      }

      .title {
        // margin-bottom: 24px;
        @include body_1_mobile;
      }

      hr {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      .button-circle {
        margin-top: 0;
        margin-bottom: 56px;
      }
    }

    &.what-say {
      margin-top: 50px;
      margin-bottom: 85px;

      .col-title {
        margin-bottom: 56px;
      }

      .item-col {
        margin-top: 56px;
      }

      .item {
        width: 85vw;
        max-width: 360px;
        min-height: 193px;

        &-col {
          .row {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-bottom: 15px;
          }
        }

        &-text {
          margin-bottom: auto;
        }

        &-author {
          margin-top: 25px;
        }
      }
    }
  }

  .about-page {
    &.banner {
      .home-page-container {
        padding-bottom: 72px;
        margin-bottom: 50px;

        > .row {
          min-height: calc(100vh - 88px - 8px - 8px - 72px);
        }

        h1 {
          @include h1_mobile;
          margin-bottom: 24px;
          padding-left: 8px;
        }

        video {
          width: 75%;
          margin-bottom: 109px;
        }

        .button-circle {
          top: unset;
          bottom: 56px;
          left: 50%;
          // right: 15%;
        }

        .col-lg-4 p {
          padding-left: 0;
        }
      }
    }

    &.services {
      margin-top: 50px;
      margin-bottom: 48px;

      .col-undertitle {
        margin-top: 56px;
        margin-bottom: 72px;
      }

      hr {
        max-width: 80%;
      }

      p {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      h1 {
        margin-bottom: 52px;
      }
    }

    &.propose {
      margin-bottom: 100px;

      .col-title {
        margin-bottom: 56px;
      }

      .buttons-wrap {
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 24px;
        padding-top: 0;
        padding-bottom: 20px;
        margin-bottom: 36px;
        overflow-x: scroll;

        button {
          figure {
            display: none;
          }
        }
      }

      [data-tab-content] {
        .slider-header {
          align-items: flex-start;
          flex-direction: column;
          padding-right: 0;
          row-gap: 32px;
          margin-bottom: 32px;

          .button-circle {
            margin-left: 50%;
          }
        }

        .slider-content {
          p {
            &:first-child {
              // margin-bottom: 16px;
            }
            // margin-right: 24px;
          }
        }
      }
    }

    &.review {
      margin-bottom: 100px;

      .top-side {
        flex-direction: column;
        margin-bottom: 32px;

        &-left h3 {
          margin-bottom: 32px;
        }
      }

      .slick-slide {
        flex-direction: column;

        .slide-svg {
          svg {
            max-width: 27px;
          }
        }
      }

      .slide-content {
        padding-left: 27px;
        height: unset;

        &-text {
          margin-top: 32px;
          margin-bottom: 56px;
        }
      }
    }
  }

  .services-page {
    &.banner {
      h1 {
        margin-bottom: 24px;
        @include body_1_mobile;
        padding-left: 16px;
      }

      p.col-lg-4 {
        max-width: 249px;
        margin-bottom: 32px;
      }

      .button-circle {
        margin-right: 45px;
      }

      .banner-about-buttons {
        margin-top: 32px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &.marquee-container {
      margin-top: 50px;
      margin-bottom: 50px;

      span {
        column-gap: 110px;
      }
    }

    &.service-section {
      margin-top: 50px;
      margin-bottom: 100px;

      .col-title {
        margin-bottom: 56px;
      }

      .col-left {
        margin-bottom: 56px;

        h2 {
          margin-bottom: 56px;
        }
      }

      .right-side-title {
        margin-bottom: 56px;
      }

      details summary {
        @include body_1;
        .accordion-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .contact-section {
    margin-top: 85px;

    .left-side {
      padding-top: 56px;
      padding-bottom: 100px;
      text-align: center;

      h3 {
        text-align: left;
      }
    }

    .marquee {
      bottom: 50%;
    }

    .button-more-info {
      margin-top: 0;
    }
  }

  .not-found-page {
    padding-top: 120px;
    padding-bottom: 41px;

    .right-side {
      margin-top: 55px;

      svg {
        max-width: 68%;
        transform: unset;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .policy-page {
    &.banner {
      .home-page-container {
        padding-top: 88px;
        padding-bottom: 56px;

        h1 {
          @include h1_mobile;
          margin-left: 8px;
        }

        & > .row {
          min-height: calc(100vh - 88px - 56px - 8px - 8px);
        }

        .p-margin {
          margin-top: 24px;
          margin-bottom: 100px;
        }
      }
    }

    &.content {
      h4 {
        transform: none;
        height: unset;
        margin-top: 32px;
        margin-bottom: 16px;

        &:first-child {
          margin-top: 0;
        }
      }

      p {
        margin-bottom: 16px;
      }
    }
  }

  .contact-page {
    margin-top: 120px;
    margin-bottom: 100px;

    .sect-title {
      margin-bottom: 32px;
      @include h2_mobile;
    }

    form {
      .row {
        row-gap: 16px;
      }

      p.body_4 {
        @include body_3_mobile;
      }

      .button-more-info {
        margin-top: 8px;
      }
    }
  }

  // ================================= COMPONENTS =================================

  .popup {
    &-cookie {
      button {
        margin-top: 5px;
      }
    }

    &-menu {
      .row {
        padding-top: 8px;
        padding-bottom: 8px;
        height: unset;
      }

      .col-lg-9 {
        order: -2;

        .menu-right-side {
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 56px;
          padding-top: 92px;

          padding: 92px 24px 56px;

          height: unset;

          nav {
            padding: 0;
          }
        }
      }

      &-blue {
        order: -1;
        margin-top: 8px;
        margin-bottom: 8px;

        .menu-left-side {
          padding: 24px;
        }
      }

      .col-12.desktop-hide {
        .menu-right-side {
          padding: 24px;

          .policy-wrap {
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            column-gap: 25px;
            row-gap: 24px;
          }
        }
      }
    }

    &-contact {
      top: 184px;

      .popup-content-main {
        padding: 38px 16px 16px;

        svg {
          width: 16px;
          height: unset;
        }
      }
    }
  }

  .marquee {
    span {
      animation: scroll 585s infinite linear;
    }
  }

  .form-item {
    label {
      @include body_4_mobile();
    }
  }

  .col-title {
    * {
      width: calc(100% - 20px);
      padding-right: 0;
    }

    &.gs_reveal {
      &::after {
        border-right: none;
        width: calc(100% - 30px);
        border-right-color: $secondary_color;

        // width: 0;
        transition: $transition;
        // border-radius: 0;
        border-right-color: transparent;
      }
    }
  }

  .col-undertitle {
    margin-top: 32px;
    margin-bottom: 52px;

    h3 {
      margin-bottom: 0;
    }

    .button-more-info {
      margin-top: 52px;
    }
  }

  // ================================= OTHER =================================

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding-left: 8px;
    padding-right: 8px;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 475px) {
}
