@keyframes opacity-0-1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate-0-360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(40px);
  }

  50% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }

  100% {
    transform: translateX(40px);
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(calc((-105% - 40px) + 100vw));
  }
}

@keyframes translate-y-animation {
  0% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-3%);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(3%);
  }
  100% {
    transform: translateY(0%);
  }
}

.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  span {
    display: inline-flex;
    transform: translateX(40px);
    animation: scroll 485s infinite linear;
    // color: $main_color;
  }

  &-reverse {
    span {
      transform: translateX(calc((-105% - 40px) + 100vw));
      animation: scroll-reverse 60s infinite linear reverse;
    }
  }
}
