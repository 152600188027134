@import '_libs.scss';
@import '_ui_kit.scss';
@import '_checkout_page.scss';

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  font-family: 'Neue Montreal', sans-serif;
  overflow-x: hidden;
  color: $main_color;
  background-color: $bg-color;
  @include body_1();
}

main {
  flex-grow: 1;
  position: relative;
  z-index: 1;
  background-color: $bg-color;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

header.header-page {
  position: fixed;
  z-index: 9999;
  top: 52px;
  left: 0;
  right: 0;
  max-width: 1360px;

  .logo {
    path {
      transition: $transition;
    }

    #logo-black {
      &.hide {
        opacity: 0;
      }
    }

    #logo-white {
      position: absolute;
      top: 0;
      left: 12px;

      &.hide {
        opacity: 0;
      }
    }
  }

  .translate-block {
    position: relative;
    z-index: 0;
    width: 95px;
    text-transform: uppercase;

    &-title {
      border: 1px solid $accent_color_1;
      border-radius: 15px;
      color: $accent_color_1;
      min-width: 95px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      cursor: pointer;
      text-transform: uppercase;

      svg {
        transform: rotate(180deg);
        transition: $transition;
      }
    }

    &.hide-content {
      .translate-block-title {
        svg {
          transform: rotate(0deg);
        }
      }

      .translate-block-content {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        top: 60%;

        * {
          pointer-events: none;
        }
      }
    }

    &-content {
      position: absolute;
      z-index: -1;
      top: 100%;
      left: 0;
      right: 0;
      padding-top: 8px;
      transition: $transition;

      button {
        padding: 8px 16px;
        margin-bottom: 4px;
        background-color: $accent_color_1;
        color: $main_color;
        @include body_1;
        width: 100%;
        border-radius: 15px;
      }
    }
  }

  .current-language {
    display: none;
  }

  .right-side {
    display: flex;
    justify-content: flex-end;
    column-gap: 24px;
  }
}

footer.footer-page {
  flex-shrink: 0;
  padding-top: 102px;
  margin-top: -50px;
  padding-bottom: 52px;
  position: relative;

  .logo {
    margin-bottom: 30px;
  }

  .button-arrow {
    margin-top: 46px;
  }

  .ul-nav {
    display: flex;
    flex-wrap: wrap;
    column-gap: 56px;
    row-gap: 53px;

    a {
      color: $bg-color;

      &:hover {
        color: $accent_color_1;
      }
    }
  }

  .to-top {
    margin-top: 62px;
    color: $accent_color_1;
    transition: $transition;

    svg {
      margin-left: 12px;
    }

    * {
      transition: $transition;
    }

    &:hover {
      color: $bg-color;

      circle {
        stroke: $bg-color;
      }

      path {
        fill: $bg-color;
      }
    }
  }
}

// ================================================= PAGES =================================================

.home-page {
  &.banner {
    margin-top: 20px;
    position: relative;
    z-index: 0;

    .home-page-container {
      // background: url('../img/home-page-banner-bg-video.mp4') no-repeat center;
      // background-size: cover;
      background-color: $secondary_color;
      border-radius: 15px;
      // padding-left: 32px;
      padding-top: 133px;
      padding-bottom: 56px;
      position: relative;
      z-index: 2;
      overflow: hidden;

      #bg-video {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        pointer-events: none;
      }

      > .row {
        min-height: calc(100vh - 133px - 20px - 20px - 56px);
        // height: auto;
        flex-direction: column;
      }

      .col-top-side {
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: 60px;
          padding-left: 32px;
        }

        .button-circle-container {
          // margin-top: 60px;
          // margin-bottom: 91px;

          margin-top: auto;
          margin-bottom: auto;
          padding-left: 13%;
        }
      }

      .col-bottom-side {
        margin-top: 91px;

        .bottom-div-wrap {
          margin-top: auto;
          border-radius: 30px;
          padding: 5px 16px;
          display: inline-flex;
          align-items: center;
          column-gap: 16px;
          background-color: $bg-color;
          margin-left: 32px;

          .stars-wrap {
            display: flex;
            align-items: center;
            column-gap: 3px;
          }
        }
      }
    }

    nav {
      position: absolute;
      z-index: 2;
      right: 32px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 16px;

      a {
        color: $bg-color;
        text-align: right;
        padding-right: 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 12px;
          // bottom: 0;
          right: 0;
          height: 1px;
          width: 0;
          // margin-top: auto;
          // margin-bottom: auto;
          background-color: $bg-color;
          transition: $transition;
        }

        &:hover,
        &.current-page {
          padding-right: 20px;
          color: $accent_color_1;

          &::after {
            width: 12px;
            background-color: $accent_color_1;
          }
        }
      }
    }
  }

  &.marquee-container {
    margin-top: 100px;
    margin-bottom: 100px;
    color: $accent_color_1;

    .h0 {
      margin-right: 65px;
    }
  }

  &.services {
    margin-top: 100px;
    margin-bottom: 100px;

    .item {
      width: 100%;
      aspect-ratio: 451 / 456;
      background-position: top center;
      background-size: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 24px;
      border-radius: 15px;
      transition: $transition;

      &-title {
        margin-bottom: auto;
      }

      hr {
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: $secondary_color_2;
        height: 1px;
        width: 100%;
      }

      &-hashtag {
        padding: 4px 18px;
        border-radius: 30px;
        background-color: rgba($bg-color, 0.5);

        &-wrap {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          column-gap: 8px;
          row-gap: 8px;
          min-height: 63px;
        }
      }

      &-web {
        background-image: url(../img/home-page-item-qa-1.png);
      }

      &-mobile {
        background-image: url(../img/home-page-item-qa-2.png);
      }

      &-ux {
        background-image: url(../img/home-page-item-qa-3.png);
      }

      &-qa {
        background-image: url(../img/home-page-item-qa-4.png);
      }

      &-cloud {
        background-image: url(../img/home-page-item-qa-5.png);
      }

      &-secure {
        background-image: url(../img/home-page-item-qa-6.png);
      }

      &:hover {
        background-size: 110%;
      }
    }
  }

  &.proces {
    margin-top: 140px;
    margin-bottom: 140px;

    .col-undertitle {
      margin-bottom: 80px;
      h3 {
        margin-bottom: 0;
      }
    }

    .item {
      padding: 24px;
      background-color: $secondary_color_3;
      border-radius: 15px;
      height: 100%;

      h4 {
        margin-top: 12px;
        margin-bottom: 24px;
      }

      li {
        @include body_2;
        margin-bottom: 12px;

        &::before {
          content: url(../img/home-page-services-li-marker.svg);
          // margin-top: 3px;
          margin-right: 8px;
          position: relative;
          top: 3px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.why-us {
    margin-top: 140px;
    margin-bottom: 140px;
    padding-top: 56px;
    padding-bottom: 80px;
    position: relative;

    .col-title {
      margin-bottom: 99px;
    }

    .row {
      align-items: flex-start;
    }

    .left-side {
      // position: sticky;
      // top: 110px;
      padding-bottom: 113px;
    }

    .button-circle {
      margin-top: 113px;

      // path[fill='#1E303C'] {
      //   fill: $bg-color;
      // }
    }

    .title {
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 140%;
    }

    hr {
      display: block;
      background-color: $accent_color_1;
      margin-top: 24px;
      margin-bottom: 46px;
    }

    .hr-line {
      margin-top: 34px;
      height: 33px;
      width: 100%;
      border-bottom-left-radius: 15px;
      border-left: 1px solid $secondary_color_2;
      border-bottom: 1px solid $secondary_color_2;
    }
  }

  &.what-say {
    margin-top: 140px;
    margin-bottom: 140px;

    .col-title {
      margin-bottom: 75px;
    }

    .item {
      min-height: 256px;
      padding: 24px 16px;
      border-radius: 15px;
      background-color: $secondary_color_3;

      display: flex;
      flex-direction: column;

      &-star-wrap {
        display: flex;
        column-gap: 2px;
        margin-bottom: 16px;
      }

      &-author {
        margin-top: auto;
        color: $secondary_color_2;
        font-size: 14px;
        line-height: 140%;

        b {
          color: $main_color;
          font-weight: 600;
          font-size: 16px;
          margin-right: 8px;
        }
      }

      &-col {
        margin-top: 80px;
      }
    }
  }
}

.about-page {
  &.banner {
    .home-page-container {
      padding-top: 133px;
      padding-bottom: 56px;
      margin-bottom: 100px;

      h1 {
        margin-bottom: 80px;
      }

      video {
        max-width: 100%;
        border-radius: 15px;
      }

      .button-circle {
        position: absolute;
        z-index: 1;
        top: -57px;
        right: -50px;
        display: block;

        .circle-bigger {
          background-color: $bg-color;
        }

        &:hover {
          .circle-bigger {
            background-color: $accent_color_1;
          }

          .circle-small {
            background-color: $bg-color;
          }
        }
      }
    }
  }

  &.services {
    margin-top: 100px;
    margin-bottom: 89px;

    .col-undertitle {
      margin-bottom: 143px;
    }

    hr {
      margin: 0;
      margin-bottom: 24px;
      margin-top: 24px;
      border-color: $secondary_color_2;
    }

    p {
      margin-top: 24px;
      margin-bottom: 64px;
    }

    h1 {
      margin-bottom: 56px;
    }
  }

  &.propose {
    margin-bottom: 140px;

    .col-title {
      margin-bottom: 36px;
    }

    .buttons-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 32px;
      padding-top: 168px;

      padding-right: 65px;

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 14px;
        @include h4;
        color: $secondary_color_2;
        transition: $transition;

        * {
          transition: $transition;
        }

        figure {
          width: 38px;
          height: 38px;
          max-width: 38px;
          max-height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          border: 1px solid $main_color;

          svg {
            min-width: 19px;
          }
        }

        &:hover,
        &.tab-active {
          color: $main_color;

          figure {
            border: 19px solid $main_color;

            svg {
              transform: rotate(30deg);
            }

            path {
              fill: $bg-color;
            }
          }
        }
      }
    }

    [data-tab-content] {
      animation: opacity-0-1 $transition;

      &.tab-hide {
        display: none;
      }

      .slider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 56px;
        column-gap: 24px;
        padding-right: 10%;
      }

      .slider-content {
        row-gap: 32px;

        p {
          &:first-child {
            margin-bottom: 16px;
          }

          span {
            margin-right: 24px;
          }

          &.body_3 {
            max-width: 434px;
          }
        }
      }
    }
  }

  &.review {
    overflow-x: hidden;

    .top-side {
      display: flex;
      justify-content: space-between;
      column-gap: 24px;
      margin-top: 75px;
      margin-bottom: 80px;

      &-left {
        h3 {
          margin-top: 24px;
        }
      }

      &-right {
        display: flex;
        column-gap: 24px;
        justify-content: flex-end;
        align-items: flex-end;

        button {
          width: 76px;
          height: 50px;
          border-radius: 15px;
          background-color: $secondary_color_3;
          transition: $transition;

          path {
            transition: $transition;
          }

          &:hover {
            background-color: $accent_color_1;

            path {
              fill: $bg-color;
            }
          }
        }
      }
    }

    .slick-list {
      overflow: visible;
      margin-right: -8vw;
      padding-right: -8vw;

      .slick-track {
        display: flex;

        .slick-slide {
          display: flex;
          height: auto;
          column-gap: 67px;
        }
      }
    }

    .slick-slide {
      margin-right: 8vw;
    }

    .slide-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      flex: 0 1 100%;

      &-text {
        margin-top: 57px;
        margin-bottom: 112px;
      }

      &-author {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: $secondary_color_2;

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 140%;
          margin-right: 7px;
          color: $main_color;
        }
      }
    }
  }
}

.services-page {
  &.banner {
    .home-page-container {
      background-color: $main_color;
      color: $bg-color;
      position: relative;
      z-index: 1;
      padding-left: 0;

      & > .row {
        flex-direction: row;
        align-content: center;
      }

      .circle {
        &-bg {
          position: absolute;
          z-index: -1;

          transition: $transition * 5 ease-in-out;

          transform: translate(-50%, -50%);
          pointer-events: none;
        }

        &-1 {
          top: 70%;
          left: 0;
        }

        &-2 {
          top: 40%;
          left: 50%;
        }
      }
    }

    h1 {
      margin-bottom: 35px;
      padding-left: 44px;
    }

    .banner-about-buttons {
      margin-top: 74px;
      a {
        width: 100%;
        background-color: $bg-color;
        color: $main_color;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 7px;
        padding: 12px 21px 12px 24px;
        margin-bottom: 24px;

        &:hover {
          background-color: $accent_color_1;
          color: $bg-color;

          svg {
            transform: rotate(30deg);
          }

          path {
            fill: $bg-color;
          }
        }
      }
    }

    .button-circle {
      .circle-bigger {
        background-color: $bg-color;
      }

      &:hover {
        .circle-bigger {
          background-color: $accent_color_1;
        }

        .circle-small {
          background-color: $bg-color;
        }
      }
    }
  }

  &.marquee-container {
    margin-top: 66px;
    margin-bottom: 119px;

    span {
      width: 120vw;
      justify-content: space-around;
      align-items: center;
      animation-duration: 30s;
    }

    img {
      width: auto;
    }
  }

  &.service-section {
    margin-top: 119px;
    margin-bottom: 140px;

    .col-title {
      margin-bottom: 75px;
    }

    .col-left {
      h2 {
        margin-bottom: 56px;
      }
    }

    .right-side-title {
      margin-bottom: 80px;
    }

    details {
      border-bottom: 1px solid $secondary_color_2;
      margin-top: 24px;
      transition: $transition;

      summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: $transition;
        padding-bottom: 16px;

        .accordion-icon {
          transition: $transition;
          transform: rotate(0deg);
        }
      }

      .accordion-content {
        transition: $transition;
        // padding-bottom: 24px;
        // padding-bottom: 16px;
        // margin-bottom: 24px;
        padding-top: 8px;
        padding-bottom: 24px;
      }

      &[open]:not([style*='overflow']) {
        summary {
          // padding-bottom: 16px;

          .accordion-icon {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.not-found-page {
  padding-top: 102px;

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin-top: 24px;
      margin-bottom: 56px;
    }
  }

  .right-side {
    svg {
      transform: translateY(50%);
    }

    img {
      max-width: 85%;
      margin-left: auto;
      display: block;
    }
  }
}

.policy-page {
  &.banner {
    margin-bottom: 80px;

    .home-page-container {
      h1 {
        margin-bottom: 0;
      }

      .p-margin {
        margin-top: 56px;
        margin-bottom: 168px;
      }
    }
  }

  &.content {
    & ~ .contact-section {
      margin-top: 70px;
    }

    h4 {
      color: $secondary_color_2;

      transform: translate(calc((1424px / 12) * -3), 100%);
      // line-height: 0px;
      height: 56px;
    }

    ul {
      list-style-type: disc;
      padding-left: 30px;
    }
  }
}

.contact-page {
  margin-top: 152px;
  margin-bottom: 140px;

  .sect-title {
    margin-bottom: 32px;
  }

  form {
    .row {
      row-gap: 24px;
    }

    .wpcf7-form-control-wrap {
      display: block;
    }

    textarea {
      width: 100%;
    }

    input {
      width: 100%;

      &[type='file'] {
        background: none;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &::-webkit-file-upload-button {
          display: none;
        }

        color: transparent;
        font-size: 0px;
      }
    }

    [data-name*='file'] {
      position: relative;
      min-height: 40px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 13px;
      align-items: center;
      margin-bottom: 26px;

      &::after {
        content: 'Прикріпити файл';
        color: $main_color;
        @include body_3;
      }

      &::before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        transition: $transition;
        border-radius: 100%;
        background: url(../img/share-file.svg) no-repeat center;
        background-size: 18px;
        background-color: $secondary_color_3;
      }

      &:hover {
        &::before {
          background-color: $accent_color_1;
          background-image: url(../img/share-file-hover.svg);
        }
      }
      .wpcf7-not-valid-tip {
        padding-left: 53px;
      }
    }

    .wpcf7-not-valid-tip {
      order: 1;
      flex: 1 1 100%;
      @include body_3;
      color: $red;
      padding-left: 24px;
      margin-top: 2px;
    }
  }
}

// ================================================= COMPONENTS =================================================

.contact-section {
  margin-top: 140px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  .marquee {
    position: absolute;
    z-index: -1;
    bottom: 219px;
    color: $secondary_color_3;

    .h0 {
      margin-left: 60px;
    }
  }

  .left-side {
    padding-top: 75px;
    padding-bottom: 101px;
  }

  .button-more-info {
    margin-top: 252px;
  }

  $animation-time: 5s linear;

  .img-2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    animation: translate-y-animation $animation-time infinite;
  }

  .img-1 {
    pointer-events: none;
    animation: translate-y-animation $animation-time infinite reverse;
  }
}

.col-title {
  position: relative;
  z-index: 0;

  * {
    background-color: $bg-color;
    display: inline-block;
    padding-left: 10px;
    padding-right: 120px;
    margin-left: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    // bottom: 0;
    left: 15px;
    right: 15px;
    // margin: auto;
    // height: 1px;
    width: calc(100% - 30px);
    // background-color: $secondary_color;

    top: 14px;
    height: 33px;
    background: transparent;
    border-top: 1px solid $secondary_color;
    border-right: 1px solid $secondary_color;
    border-top-right-radius: 15px;

    pointer-events: none;
  }

  &.gs_reveal {
    &::after {
      width: 0;
      transition: $transition;
      // border-radius: 0;
      border-right-color: transparent;
    }

    &[style*='opacity: 1'] {
      &::after {
        width: calc(100% - 30px);
        border-right-color: $secondary_color;
      }
    }
  }
}

.col-undertitle {
  margin-top: 75px;
  margin-bottom: 80px;

  h3 {
    margin-bottom: 56px;
  }
}

// ================================================= OTHER =================================================

details {
  summary {
    &::marker,
    &::-webkit-details-marker {
      content: none;
    }
  }
}

body.menu-open {
  .popup-menu {
    &,
    * {
      visibility: visible;
      pointer-events: all;
      opacity: 1;

      // animation: opacity-0-1 $transition linear;
    }

    a,
    a * {
      transition: $transition;
    }

    .menu-left-side {
      left: 0;

      * {
        transform: translateY(0px);
      }
    }

    .menu-right-side {
      right: 0;

      a {
        transform: translateY(0px) rotateX(0deg);
      }
    }
  }

  &:not(.menu-animation) {
    .popup-menu {
      a,
      a * {
        transition-delay: 0s !important;
      }
    }
  }
}

body:not(.menu-open) {
  .popup-menu {
    * {
      transition-delay: 0s !important;
    }
  }
}

@import '_popups.scss';

.logo {
  path {
    transition: $transition;
  }

  &:hover,
  &:focus {
    path {
      fill: $accent_color_1;
      transition: $transition;
    }
  }
}

.menu-button-open {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  position: relative;
  background-color: rgba($accent_color_1, 0.3);
  transition: $transition;

  span {
    position: absolute;
    left: 10px;
    height: 2px;
    width: 23px;
    border-radius: 2px;
    background-color: $bg-color;
    transition: $transition;

    &:nth-child(1) {
      top: 17px;
    }

    &:nth-child(2) {
      top: 25px;
      width: 16px;
    }
  }

  &:hover,
  &:focus {
    background-color: $accent_color_1;
  }
}

.menu-open {
  .menu-button-open {
    span {
      top: 50%;
      left: 11px;
      width: 23px;

      &:nth-child(1) {
        top: 50%;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        top: 50%;
        width: 23px;
      }
    }
  }
}

#trp-floater-ls {
  opacity: 0.01;
  visibility: hidden;
  pointer-events: none;
}

// Always in the end
@import '_media.scss';
// ___________________
