/** @format */

// vars
$main_color: #1e303c;
$bg-color: #f3f3f3;
$accent_color_1: #2ca8fe;
$secondary_color: #d3d0dc;
$secondary_color_2: #9e98af;
$secondary_color_3: #edecee;

$red: #eb5757;

$transition: 0.5s;

@mixin body_1 {
  font-family: Involve, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_1_mobile {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_2 {
  font-family: Involve, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_2_mobile {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

@mixin body_3 {
  font-family: Involve, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_3_mobile {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_4 {
  font-family: Involve, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

@mixin body_4_mobile {
  font-size: 10px;
  font-weight: 400;
  line-height: 140%;
}

@mixin h0 {
  font-family: Involve, sans-serif;
  font-size: 100px;
  font-weight: 400;
  line-height: 140%;
}

@mixin h0_mobile {
  font-size: 70px;
  font-weight: 400;
  line-height: 98px;
}

@mixin h1 {
  font-family: Involve, sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 140%;
}

@mixin h1_mobile {
  font-size: 35px;
  font-weight: 400;
  line-height: 49px;
}

@mixin h2 {
  font-family: Involve, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 140%;
}

@mixin h2_mobile {
  font-size: 28px;
  font-weight: 400;
  line-height: 39.2px;
}

@mixin h3 {
  font-family: Involve, sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 140%;
}

@mixin h3_mobile {
  font-size: 23px;
  font-weight: 400;
  line-height: 32px;
}

@mixin h4 {
  font-family: Involve, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.1em;
}

@mixin h4_mobile {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.1em;
}

.h0 {
  @include h0();
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

.body_1 {
  @include body_1();
}

.body_2 {
  @include body_2();
}

.body_3 {
  @include body_3();
}

.body_4 {
  @include body_4();
}

.form-item {
  position: relative;

  label {
    @include body_4();
    position: absolute;
    top: 20px;
    left: 24px;
    transition: $transition;
    pointer-events: none;
    color: $secondary_color_2;

    &.up {
      font-size: 8px;
      top: 8px;
    }
  }
}

input,
textarea {
  background-color: $secondary_color_3;
  color: $main_color;
  outline: none;
  border-radius: 15px;
  border: 1px solid $secondary_color_3;
  caret-color: $accent_color_1;
  padding: 24px 24px 16px;
  @include body_4();
  transition: $transition;

  &:hover,
  &:focus {
    border-color: $accent_color_1;
  }

  &.not-empty {
    border-color: $secondary_color;

    &:hover,
    &:focus {
      border-color: $accent_color_1;
    }
  }
}

textarea {
  min-height: 80px;
  height: 80px;
}

// autocomplete background none
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  color: $main_color;
  -webkit-text-fill-color: $main_color;
  -webkit-box-shadow: 0 0 0px 1000px $bg-color inset;
}

::selection {
  color: $bg-color;
  background-color: $main_color;
}

a {
  transition: $transition;
  color: $main_color;

  svg,
  path {
    transition: $transition;
  }

  &:hover,
  &:focus {
    transition: $transition;
    color: $accent_color_1;
    text-decoration: none;
  }
}

// ==================== components =====================

#mouse-circle {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  background-color: $accent_color_1;
  position: fixed;
  z-index: 999999;
  transform: translate(-50%, -50%);

  pointer-events: none;

  &.active {
    .mouse-circle-black-dot {
      width: 100%;
      height: 100%;
    }
  }
}

.mouse-circle-black-dot {
  height: 9px;
  width: 9px;
  background-color: $main_color;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  transition: $transition;
}

.color-accent_color_1 {
  color: $accent_color_1;
}

.color-secondary_color_2 {
  color: $secondary_color_2;
}

.button-arrow {
  min-width: 244px;
  display: inline-flex;
  column-gap: 15px;
  align-items: center;
  justify-content: space-between;
  color: $main_color;

  svg,
  path {
    transition: $transition;
  }

  &:hover {
    color: $accent_color_1;

    svg {
      transform: rotate(30deg);
    }

    path {
      &[fill='#F3F3F3'] {
        fill: $accent_color_1;
      }
    }
  }
}

.button-circle {
  width: 126px;
  height: 126px;
  position: relative;
  z-index: 0;
  animation: rotate-0-360 infinite 10s linear;

  svg {
    display: inline-block;
    // width: 132px;
    // margin-right: -6px;
  }

  .circle {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 100%;
    transition: $transition;

    &-small {
      height: 13px;
      width: 13px;
      background-color: $accent_color_1;
    }

    &-bigger {
      width: 35px;
      height: 35px;
      background-color: $main_color;
    }
  }

  &:hover,
  &:focus {
    animation-play-state: paused;

    .circle {
      &-small {
        background-color: $main_color;
      }

      &-bigger {
        background-color: $accent_color_1;
        width: 123px;
        height: 100%;
      }
    }
  }
}

.button-cookie {
  padding: 8px 24px;
  @include body_3();
  background-color: $bg-color;
  color: $main_color;
  transition: $transition;
  border-radius: 30px;

  &:hover,
  &:focus {
    transition: $transition;
    background-color: $accent_color_1;
    color: $bg-color;
  }
}

.bg-section-dark {
  background-color: $main_color;
  color: $secondary_color;

  .col-title {
    * {
      background-color: $main_color;
    }
    &::after {
      background-color: $main_color;
    }
  }

  .button-arrow {
    color: $bg-color;

    &:hover {
      color: $accent_color_1;
    }
  }

  ::selection {
    background-color: $accent_color_1;
    color: $bg-color;
  }
}

.button-more-info {
  padding: 20px 31px 20px 86px;
  background-color: $bg-color;
  color: $accent_color_1;
  border: 1px solid $accent_color_1;
  border-radius: 30px;
  position: relative;
  @include body_3();
  transition: $transition;

  .svg-wrap {
    width: 62px;
    height: 62px;
    height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $accent_color_1;
    transition: $transition;

    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;

    * {
      transition: $transition;
    }
  }

  &:hover,
  &:focus {
    background-color: $accent_color_1;
    color: $bg-color;
    padding-left: 32px;
    padding-right: 86px;

    .svg-wrap {
      background-color: $bg-color;
      left: calc(100% - 62px);
      // transform: translate(-100%);

      svg {
        transform: rotate(390deg);
      }

      path {
        fill: $accent_color_1;
      }
    }
  }
}

.row {
  margin-left: -12px;
  margin-right: -12px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 12px;
  padding-right: 12px;
}

.container {
  max-width: 1424px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-left: 12px;
  padding-right: 12px;
}
