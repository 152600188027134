.popup {
  position: fixed;
  z-index: 999;
  transition: $transition;
  opacity: 1;

  &-preloader {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    background-color: $bg-color;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 46px;
    padding-bottom: 36px;

    .preloader {
      &-number {
        font-size: 100px;
        font-weight: 400;
        line-height: 140%;
      }

      &-progres {
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 0;
        background-color: $accent_color_1;
        // transition: $transition;

        &-top {
          top: 0;
          height: 50%;
        }

        &-bottom {
          bottom: 0;
          height: 50%;
        }

        &-wrap {
          position: absolute;
          top: calc(100% - 53px);
          bottom: 50px;
          left: 46px;
          right: 46px;
          height: 3px;
          width: calc(100% - 92px);
          background-color: #d9d9d9;
          border-radius: 50px;

          transition: $transition;
        }

        &-grey {
          height: 0;
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          margin-top: auto;
          margin-bottom: auto;
          background-color: $bg-color;

          transition: $transition;
          transition-delay: $transition;
        }
      }
    }

    &.page-ready {
      .preloader {
        &-progres {
          &-wrap {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border-radius: 0;
            overflow: hidden;
          }

          &-grey {
            height: 100%;
          }
        }
      }
    }

    &.page-ready-2 {
      background-color: transparent;

      .preloader {
        &-progres {
          &-wrap {
            background-color: transparent;
          }

          transition: $transition;
          transition-delay: $transition;
          height: 0;
        }
      }
    }
  }

  &-cookie {
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $main_color;
    color: $bg-color;
    padding: 14px;

    .row {
      align-items: center;
    }
  }

  &-menu {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $bg-color;
    height: 100vh;
    width: 100vw;

    padding-bottom: 10vh;
    overflow-y: scroll;
    overflow-x: hidden;
    // animation: opacity-0-1 $transition linear;

    // display: none;
    &,
    * {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      transition: $transition;
    }

    .row {
      padding-top: 20px;
      padding-bottom: 20px;
      height: 100vh;
      align-items: stretch;
    }

    .menu-left-side {
      border-radius: 15px;
      background-color: $accent_color_1;
      padding: 12px 24px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 24px;

      left: -200px;
      position: relative;

      * {
        transform: translateY(30px);
      }

      a {
        padding: 10px 32px;
        width: 100%;
        color: $bg-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $bg-color;
        border-radius: 15px;
        transition: $transition;

        svg,
        path {
          transition: $transition;
        }

        &:hover {
          background-color: $bg-color;
          color: $accent_color_1;

          svg {
            transform: rotate(30deg);
          }

          path {
            fill: $accent_color_1;
          }
        }
      }
    }

    .menu-right-side {
      position: relative;
      right: -200px;

      border-radius: 15px;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 24px;
      padding-right: 32px;
      padding-left: 70px;

      color: $bg-color;
      background-color: $main_color;

      nav {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        height: 100%;
        padding-top: 118px;
        justify-content: center;
      }

      a {
        color: $bg-color;
        transform: translateY(30px) rotateX(75deg);

        * {
          transition: $transition;
        }

        &:hover,
        &:focus {
          &,
          & * {
            color: $accent_color_1;
            transition: $transition;
          }

          small {
            margin-right: 12%;
          }
        }

        small {
          vertical-align: top;
        }

        // &.current-page {
        //   color: $accent_color_1;

        //   small {
        //     margin-right: 12%;
        //   }
        // }
      }

      .policy-wrap {
        padding-left: 49px;
        display: flex;
        column-gap: 48px;
        row-gap: 24px;
        margin-top: 74px;

        span {
          margin-left: auto;
        }
      }
    }
  }

  &-contact {
    top: 139px;
    left: 0;
    right: 0;

    .popup-content-main {
      padding: 32px 56px 32px 24px;
      background-color: $main_color;
      color: $bg-color;
      border-radius: 15px;
      position: relative;
    }

    .popup-button-close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }
}
